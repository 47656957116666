import { requestPost } from '@/utils/axios.js'
export const userProfile = (params) => {
  return requestPost('/passport/v1/user/profile', params)
}

export const userInfosUpdate = (params) => {
  return requestPost('/passport/v1/user/profile/update', params)
}

export const avatarAddressList = (params) => {
  return requestPost('/passport/v1/public/avatar-address/list', params)
}
