<template>
  <div class="profile-picture-wrapper ub">
    <div class="profile-picture-wrapper-item tsr-item ub ub-cen" v-for="avatar in avatatUrlList" :key="avatar"
      :class="{ 'tsr-active': avatar === inUse || avatar === active }">
      <img class="avatar-img" :src="avatar" @click="() => onChoose(avatar)" />
      <span class="use inUse" v-show="avatar=== inUse">
        In Use
      </span>
      <img src="@/assets/images/profile/avatar-active.svg" class="choose" v-show="avatar === active"/>
    </div>
    <van-button type="primary" block :color="active ? btnColor : defaultColor" @click="userInfosUpdateFn" class="btn-logout">
      {{ active ? $t('profile.changeNow') : $t('profile.inUse') }}
    </van-button>
  </div>
</template>

<script>
import { avatarAddressList, userInfosUpdate } from '@/api/profile.js'
export default {
  data () {
    return {
      avatatUrlList: [],
      btnColor: 'var(--primary-color)',
      defaultColor: 'var(--main-text-3)',
      inUse: '',
      active: null,
      userInfo: {}
    }
  },
  created () {
  },
  mounted () {
    this.userInfo = this.$store.state.userInfo
    this.inUse = this.$store.state.userInfo?.avatar_address
    this.avatarAddressListFn()
  },
  methods: {
    userInfosUpdateFn () {
      if (this.active === this.inUse || !this.active) {
        return false
      }
      const avatarAddress = this.active
      userInfosUpdate({
        avatar_address: avatarAddress
      }).then(res => {
        this.inUse = this.active
        this.$store.state.userInfo.avatar_address = this.active
        this.$store.dispatch('updateUserInfo', this.userInfo)
        this.$toast.clear()
      })
    },
    avatarAddressListFn () {
      avatarAddressList({
      }).then(res => {
        this.avatatUrlList = res.data
        console.log(res, 'avatarAddressListFn====', this.avatatUrlList)
        this.$toast.clear()
      })
    },
    onChoose (avatar) {
      if (avatar === this.inUse) return
      this.active = avatar
    }
  }
}
</script>
<style lang="less">
.profile-picture-wrapper {
  padding: 48px 30px 0;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: var(--background-color-4);
  &-item {
    position: relative;
    margin-bottom: 24px;
    padding: 26px 24px 24px 26px;
    border-radius: 16px;
    background: var(--background-color-2);
    overflow: hidden;
    box-sizing: border-box;
    .avatar-img{
      border-radius: 180px;
      width: 160px;
      height: 160px;
    }
  }

  .btn-logout {
    position: fixed;
    bottom: 20px;
    left: 50%;
    width: 90%;
    transform: translateX(-50%);
    border-radius: 8px;
  }
  .choose {
    position: absolute;
    bottom: 0;
    right: 0;
    overflow: hidden;
    width: 32px;
    height: 32px;
    // z-index: 1;
  }
  .use {
    padding: 0 8px;
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    width: 88px;
    height: 36px;
    line-height: 36px;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    box-sizing: border-box;
    // z-index: 1;
    // &::after {
    //   content: '';
    //   position: absolute;
    //   bottom: -1px;
    //   right: -1px;
    //   background-color: var(--primary-color);
    //   display: inline-block;
    //   width: 10px;
    //   height: 10px;
    // }
  }
  .inUse {
    background: url('~@/assets/images/profile/avatar-use.svg') no-repeat;
    background-size: cover;
  }
  .tsr-item {
    border: 1px solid transparent;
  }
  .tsr-active {
    border: 1px solid var(--primary-color);
    // &::after {
    //   content: '';
    //   position: absolute;
    //   bottom: -1px;
    //   right: -1px;
    //   background-color: var(--primary-color);
    //   display: inline-block;
    //   width: 32px;
    //   height: 32px;
    //   border-top-left-radius: 16px;
    // }
  }
}
</style>
